import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyAgC--Xl86llCCqOwqE1nk0DkqUPZe3kKU",
  authDomain: "universe4kids-133e6.firebaseapp.com",
  projectId: "universe4kids-133e6",
  storageBucket: "universe4kids-133e6.appspot.com",
  appId: "1:1000270774520:web:70af33d7140a8ce8517b0d",
});

export const firestore = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
