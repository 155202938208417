import { PlusIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Alert from "../common/Alert";
import { Actions, useAPIRequest } from "../common/api-request";
import { PrimaryButton, PrimaryOutlineButton } from "../common/Buttons";
import Card from "../common/Card";
import { Chip } from "../common/Chips";
import { LoadingContext } from "../common/Contexts";
import Modal from "../common/Modal";
import { parseError } from "../common/utils";
import { getProductSetting, saveProductSetting } from "./SettingRepo";
import SettingsDialogContent from "./SettingsDialogContent";

function ProductSetting() {
  const [showDialog, setShowDialog] = useState(false);
  const [productTitle, setProductTitle] = useState("");

  const [sizeList, setSizeList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [materialList, setMaterialList] = useState([]);

  const [state, requestSave] = useAPIRequest(saveProductSetting);
  const [setting, requestSetting] = useAPIRequest(getProductSetting);

  const loadingContext = useContext(LoadingContext);

  useEffect(() => {
    requestSetting();

    return () => {
      loadingContext.setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadingContext.setLoading(setting.status === Actions.loading);
    if (setting.status === Actions.success) {
      let payLoad = setting.payload;
      setColorList(payLoad.colorChoices ?? []);
      setSizeList(payLoad.sizeChoices ?? []);
      setMaterialList(payLoad.materialChoices ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting]);

  useEffect(() => {
    if (state.status === Actions.success) {
      toast.success("Product Setting saved successfully.");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  function removeColor(i) {
    let list = [...colorList];
    list.splice(i, 1);
    setColorList(list);
  }

  function removeSize(i) {
    let list = [...sizeList];
    list.splice(i, 1);
    setSizeList(list);
  }

  function removeMaterial(i) {
    let list = [...materialList];
    list.splice(i, 1);
    setMaterialList(list);
  }

  function handleSubmit(e) {
    e.preventDefault();
    let ps = {
      colorChoices: colorList,
      sizeChoices: sizeList,
      materialChoices: materialList,
    };
    requestSave(ps);
  }

  return (
    <div>
      <Modal
        title={
          productTitle === "size"
            ? "Add Size"
            : productTitle === "color"
            ? "Add Color"
            : "Add Material"
        }
        isOpen={showDialog}
      >
        <SettingsDialogContent
          handleClose={(value, name) => {
            setShowDialog(false);
            if (value && value.length !== 0) {
              if (name === "size") {
                let list = [...sizeList];
                list.push(value);
                setSizeList(list);
              } else if (name === "color") {
                let list = [...colorList];
                list.push(value);
                setColorList(list);
              } else {
                let list = [...materialList];
                list.push(value);
                setMaterialList(list);
              }
            }
          }}
          title={productTitle}
          list={
            productTitle === "size"
              ? sizeList
              : productTitle === "color"
              ? colorList
              : materialList
          }
        />
      </Modal>
      <div>
        <form onSubmit={handleSubmit}>
          {state.status === Actions.failure && (
            <Alert alertClass="alert-error mb-4" closeable>
              {parseError(state.error)}
            </Alert>
          )}

          <Card>
            <Card.Body>
              <div>
                <div className="flex items-center">
                  <h6 className="text-lg font-bold text-gray-900">Size</h6>
                  <PrimaryOutlineButton
                    className="ml-auto"
                    small={true}
                    onClick={() => {
                      setShowDialog(true);
                      setProductTitle("size");
                    }}
                  >
                    <PlusIcon className="w-4 h-4 mr-2"></PlusIcon>
                    Add New
                  </PrimaryOutlineButton>
                </div>
                <hr className="mt-1 mb-3" />
                <div className="flex flex-wrap">
                  {sizeList.map((s, i) => (
                    <Chip.Default key={i} className="mr-2 mb-2">
                      {s}
                      <Chip.DeleteAction onClick={() => removeSize(i)} />
                    </Chip.Default>
                  ))}
                </div>
              </div>
              <div className="mt-5 mb-2">
                <div className="flex items-center">
                  <h6 className="text-lg font-bold text-gray-900">Color</h6>
                  <PrimaryOutlineButton
                    className="ml-auto"
                    small={true}
                    onClick={() => {
                      setShowDialog(true);
                      setProductTitle("color");
                    }}
                  >
                    <PlusIcon className="w-4 h-4 mr-2"></PlusIcon>
                    Add New
                  </PrimaryOutlineButton>
                </div>
                <hr className="mt-1 mb-3" />
                <div className="flex flex-wrap">
                  {colorList.map((c, i) => (
                    <Chip.Default key={i} className="mr-2 mb-2">
                      {c}
                      <Chip.DeleteAction onClick={() => removeColor(i)} />
                    </Chip.Default>
                  ))}
                </div>
              </div>
              <div className="mt-5 mb-2">
                <div className="flex items-center">
                  <h6 className="text-lg font-bold text-gray-900">Material</h6>
                  <PrimaryOutlineButton
                    className="ml-auto"
                    small={true}
                    onClick={() => {
                      setShowDialog(true);
                      setProductTitle("material");
                    }}
                  >
                    <PlusIcon className="w-4 h-4 mr-2"></PlusIcon>
                    Add New
                  </PrimaryOutlineButton>
                </div>
                <hr className="mt-1 mb-3" />
                <div className="flex flex-wrap">
                  {materialList.map((m, i) => (
                    <Chip.Default key={i} className="mr-2 mb-2">
                      {m}
                      <Chip.DeleteAction onClick={() => removeMaterial(i)} />
                    </Chip.Default>
                  ))}
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <div className="flex">
                <PrimaryButton
                  className="ml-auto"
                  type="submit"
                  disabled={
                    state.status === Actions.loading ||
                    setting.status === Actions.loading
                  }
                  loading={state.status === Actions.loading}
                >
                  Save
                </PrimaryButton>
              </div>
            </Card.Footer>
          </Card>
        </form>
      </div>
    </div>
  );
}

export default ProductSetting;
