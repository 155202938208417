import { useState } from "react";
import { DefaultButton, PrimaryButton } from "../common/Buttons";
import { Input } from "../common/FormControls";

function SettingsDialogContent({ handleClose, title, list = [] }) {
  const [value, setValue] = useState("");
  const [error, setError] = useState();

  let label = "";
  let name = "";
  let placeholder = "";

  if (title === "size") {
    label = "Size";
    name = "size";
    placeholder = "Enter size";
  } else if (title === "color") {
    label = "Color";
    name = "color";
    placeholder = "Enter color";
  } else if (title === "phone") {
    label = "Phone No";
    name = "phone";
    placeholder = "Enter phone number";
  } else {
    label = "Material";
    name = "material";
    placeholder = "Enter material type";
  }

  function addSettingData() {
    if (title === "phone") {
      addPhone();
    } else if (title === "size") {
      addSize();
    } else if (title === "color") {
      addColor();
    } else {
      addMaterial();
    }
  }

  function addPhone() {
    if (!value || value.trim().length === 0) {
      setError("Please enter phone number.");
      return;
    }
    handleClose(value);
  }

  function addSize() {
    if (!value || value.trim().length === 0) {
      setError("Please enter size.");
      return;
    } else {
      let duplicate = checkDuplicate(list);
      if (duplicate) {
        setError("Size already exists.");
        return;
      }
    }
    handleClose(value, name);
  }

  function addColor() {
    if (!value || value.trim().length === 0) {
      setError("Please enter color.");
      return;
    } else {
      let duplicate = checkDuplicate(list);
      if (duplicate) {
        setError("Color already exists.");
        return;
      }
    }
    handleClose(value, name);
  }

  function addMaterial() {
    if (!value || value.trim().length === 0) {
      setError("Please enter material type.");
      return;
    } else {
      let duplicate = checkDuplicate(list);
      if (duplicate) {
        setError("Material type already exists.");
        return;
      }
    }
    handleClose(value, name);
  }

  function checkDuplicate(list) {
    return list.find(
      (s) =>
        s.toLowerCase().trim().replace(/\s+/g, "") ===
        value.toLowerCase().trim().replace(/\s+/g, "")
    );
  }

  return (
    <div>
      <form className="flex flex-col mt-4">
        <div className="mb-6">
          <Input
            label={label}
            name={name}
            placeholder={placeholder}
            value={value}
            error={error}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            type="text"
          />
        </div>
        <div className="flex flex-row-reverse space-x-reverse space-x-2">
          <PrimaryButton onClick={addSettingData}>Add</PrimaryButton>
          <DefaultButton
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </DefaultButton>
        </div>
      </form>
    </div>
  );
}

export default SettingsDialogContent;
