import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseImagePath } from "../App";
import Alert from "../common/Alert";
import { Actions, useAPIRequest } from "../common/api-request";
import { DangerButton } from "../common/Buttons";
import Card from "../common/Card";
import { LoadingContext } from "../common/Contexts";
import { Select } from "../common/FormControls";
import { ConfirmModal } from "../common/Modal";
import { formatPrice, formatTimestamp, parseError } from "../common/utils";
import { getOrder, updateOrderStatus } from "./OrderRepo";

const cancelledState = "CANCELLED";

function OrderDetail() {
  let params = useParams();
  const navigate = useNavigate();
  const [state, requestSave] = useAPIRequest(updateOrderStatus);
  const [dataState, requestData] = useAPIRequest(getOrder);

  const loadingContext = useContext(LoadingContext);

  const [showConfirm, setShowConfirm] = useState(false);
  const [order, setOrder] = useState({
    products: [],
  });

  useEffect(() => {
    if (params.id) {
      requestData(params.id);
    }

    return () => {
      loadingContext.setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    loadingContext.setLoading(dataState.status === Actions.loading);
    if (dataState.status === Actions.success) {
      setOrder(dataState.payload ?? {});
    }

    if (dataState.status === Actions.failure) {
      toast.error(parseError(dataState.error));
      navigate("/orders", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataState]);

  useEffect(() => {
    if (state.status === Actions.success) {
      toast.success("Order status updated successfully.");
      requestData(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  function updateStatus(s) {
    requestSave({ id: params.id, status: s });
  }

  function getProductImageUrl(p) {
    if (p.product.images && p.product.images.length > 0) {
      return `${baseImagePath}/products%2F${p.product.images[0]}?alt=media`;
    }

    return "/placeholder.png";
  }
  if (dataState.status !== Actions.success) {
    return <div></div>;
  }
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
      <ConfirmModal
        message="Are you sure to cancel order?"
        isOpen={showConfirm}
        handleClose={(result) => {
          setShowConfirm(false);
          if (result) {
            updateStatus(cancelledState);
          }
        }}
      />

      {dataState.status === Actions.failure && (
        <Alert alertClass="alert-error mb-4" closeable>
          {parseError(dataState.error)}
        </Alert>
      )}

      <div className="lg:col-span-3">
        <Card>
          <Card.Header className="h-16 flex">
            <div className="flex flex-grow items-center justify-between">
              <h4 className="mb-0">{order.orderNumber}</h4>
              {order.status !== cancelledState && order.status !== "DELIVERED" && (
                <DangerButton
                  className="ml-auto"
                  onClick={() => setShowConfirm(true)}
                  disabled={
                    state.status === Actions.loading ||
                    dataState.status === Actions.loading
                  }
                >
                  Cancel Order
                </DangerButton>
              )}
            </div>
          </Card.Header>
          <Card.Body>
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
              <div className="lg:col-span-5">
                <h6 className="font-semibold text-lg mb-1">Delivery Info</h6>
                <div className="text-gray-600">
                  Name:&nbsp;
                  <span className="text-black">{order.customer}</span>
                </div>
                <div className="text-gray-600">
                  Phone:&nbsp;
                  <span className="text-black">{order.phoneNumber}</span>
                </div>
                <div className="text-gray-600">
                  Township:&nbsp;
                  <span className="text-black">
                    {order.township ? order.township.township : ""}
                  </span>
                </div>
                <div className="text-gray-600">
                  Address:&nbsp;
                  <span className="text-black">{order.address}</span>
                </div>
              </div>
              <div className="lg:col-span-4">
                <h6 className="font-semibold text-lg mb-1">Payment Info</h6>
                <div className="text-gray-600">
                  {`Total Products:  ${order.totalProduct}`}
                </div>
                <div className="text-gray-600">
                  {`Subtotal:  ${formatPrice(order.subtotal)} Ks`}
                </div>
                <div className="text-red-600">
                  {`Discount:  -${formatPrice(order.discount)}  Ks`}
                </div>
                <div className="text-green-600">
                  {`Delivery fee:  +${formatPrice(order.deliveryFee)} Ks`}
                </div>
                <h5 className="text-gray-900 font-semibold">
                  {`Total Price:  ${formatPrice(order.totalPrice)}  Ks`}
                </h5>
              </div>
              <div className="lg:col-span-3">
                <h6 className="font-semibold text-lg mb-1">Status</h6>
                <div className="flex">
                  {order.status === cancelledState && (
                    <h6 className="font-semibold text-lg text-red-600">
                      {cancelledState}
                    </h6>
                  )}
                  {order.status !== cancelledState && (
                    <Select
                      onChange={(event) => {
                        updateStatus(event.target.value);
                      }}
                      value={order.status}
                      disabled={
                        state.status === Actions.loading ||
                        dataState.status === Actions.loading
                      }
                    >
                      <option value="PENDING">Pending</option>
                      <option value="CONFIRMED">Confirmed</option>
                      <option value="DELIVERED">Delivered</option>
                    </Select>
                  )}
                </div>
              </div>
            </div>
            <hr className="my-5 lg:col-span-12"></hr>
            <div className="grid lg:grid-cols-2 gap-4 lg:col-span-12">
              {order.products.map((p, i) => {
                return (
                  <div
                    key={i}
                    className="flex space-x-3"
                    role="button"
                    onClick={() => navigate(`/products/${p.product.id}`)}
                  >
                    <div className="bg-gray-100 rounded flex items-center p-1">
                      <img
                        src={getProductImageUrl(p)}
                        alt="product"
                        style={{ height: 100, width: 120 }}
                        className="object-contain"
                      />
                    </div>
                    <div className="flex flex-col flex-grow">
                      <h6 className="text-gray-600 font-medium">
                        {p.product.name}
                      </h6>
                      <div className="text-gray-400 text-sm">
                        {`Colors: [${p.product.colors.join(", ")}], Size: ${
                          p.product.size
                        }, Material: ${p.product.material}`}
                      </div>
                      <div className="text-gray-900 font-semibold mt-auto">
                        {p.quantity} x{" "}
                        {`${formatPrice(p.price / p.quantity)} Ks`}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card.Body>
          <Card.Footer>
            <h6 className="text-gray-400">
              Order Date: {formatTimestamp(order.createdAt)}
            </h6>
          </Card.Footer>
        </Card>
      </div>
      <div className="lg:col-span-1">
        <Card className="h-full">
          <Card.Header className="h-16">
            <div className="flex items-center py-2">
              <h4>Note</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <p className="text-gray-600">{order.note ?? ""}</p>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default OrderDetail;
