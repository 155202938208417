import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Actions, useAPIRequest } from "../common/api-request";
import { PrimaryButton } from "../common/Buttons";
import Card from "../common/Card";
import { ChipGroups } from "../common/Chips";
import { LoadingContext } from "../common/Contexts";
import { CheckBox, Input, Select, TextArea } from "../common/FormControls";
import { getProductEdit, saveProduct } from "./ProductRepo";
import ProductImages from "../product/ProductImages";
import { toast } from "react-toastify";
import Alert from "../common/Alert";
import { parseError, parseNumber } from "../common/utils";

// const statusList = ["AVAILABLE", "OUT OF STOCK"];

export const initialProductData = {
  id: undefined,
  name: "",
  price: "",
  discount: {
    value: "",
    type: "fixed",
  },
  isDiscount: false,
  newArrival: false,
  popular: false,
  category: "",
  size: "",
  material: "",
  colors: [],
  available: true,
  barcode: "",
  code: "",
  images: [],
  hidden: false,
  description: "",
  urls: [],
  files: [],
};

function ProductEdit() {
  let params = useParams();
  const navigate = useNavigate();
  const loadingContext = useContext(LoadingContext);
  const [dataState, requestData] = useAPIRequest(getProductEdit);
  const [state, requestSave] = useAPIRequest(saveProduct);
  const [categoryList, setCategoryList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [materialList, setMaterialList] = useState([]);

  const [initialValues, setInitialValues] = useState({ ...initialProductData });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialValues },
    validate: (values) => {
      let errors = {};
      if (!values.name || values.name.trim().length === 0) {
        errors.name = "Please enter product name.";
      }
      if (!values.price || values.price.length === 0) {
        errors.price = "Please enter product price.";
      }
      if (!values.code || values.code.length === 0) {
        errors.code = "Please enter product code.";
      }
      if (!values.category) {
        errors.category = "Please select category.";
      }
      if (!values.size) {
        errors.size = "Please select size.";
      }

      if (!values.material) {
        errors.material = "Please select material.";
      }

      if (!values.colors || values.colors.length === 0) {
        errors.colors = "Please select color.";
      }

      if (
        values.isDiscount &&
        (!values.discount.value || isNaN(values.discount.value))
      ) {
        errors.discount = "Please enter discount price.";
      } else if (
        values.isDiscount &&
        values.discount.type === "percent" &&
        values.discount.value > 100
      ) {
        errors.discount = "Discount percentage must not exceed 100%.";
      }

      return errors;
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      let product = { ...values };
      product["price"] = parseNumber(values.price);
      if (product.isDiscount || product.discount.value) {
        product.discount.value = parseNumber(product.discount.value);
      }
      requestSave(product);
      //console.log(product);
      //formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    requestData(params.id);

    return () => {
      loadingContext.setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadingContext.setLoading(dataState.status === Actions.loading);

    if (dataState.status === Actions.success) {
      const payload = dataState.payload;
      setCategoryList(payload.categories ?? []);
      setColorList(payload.productSetting.colorChoices ?? []);
      setSizeList(payload.productSetting.sizeChoices ?? []);
      setMaterialList(payload.productSetting.materialChoices ?? []);

      let product = payload.product ?? {};
      product.urls = [...product.images];
      setInitialValues({ ...initialProductData, ...product });
    }

    if (dataState.status === Actions.failure) {
      toast.error(parseError(dataState.error));
      navigate("/products", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataState]);

  useEffect(() => {
    if (state.status !== Actions.loading) {
      formik.setSubmitting(false);
    }

    if (state.status === Actions.success) {
      toast.success("Product saved successfully.");
      navigate("/products", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
      <div className="lg:col-span-3">
        <form onSubmit={formik.handleSubmit}>
          <Card>
            <Card.Header>
              <div className="flex items-center">
                <h3>{`${params.id ? "Update" : "Add"} Product`}</h3>
                <PrimaryButton
                  type="submit"
                  className={"ml-auto"}
                  disabled={
                    formik.isSubmitting || dataState.status === Actions.loading
                  }
                  loading={formik.isSubmitting}
                >
                  Save
                </PrimaryButton>
              </div>
            </Card.Header>
            <Card.Body>
              {state.status === Actions.failure && (
                <Alert alertClass="alert-error mb-4" closeable>
                  {parseError(state.error)}
                </Alert>
              )}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="lg:col-span-2">
                  <Input
                    label="Name *"
                    name="name"
                    placeholder="Enter product name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.errors.name}
                  />
                </div>

                <div className="lg:col-span-1">
                  <Input
                    label="Product Code *"
                    name="code"
                    placeholder="Enter product code"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    error={formik.errors.code}
                  />
                </div>
                <div className="lg:col-span-1">
                  <Input
                    label="Barcode"
                    name="barcode"
                    placeholder="Enter barcode"
                    value={formik.values.barcode}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="lg:col-span-2">
                  <Select
                    label="Category *"
                    name="category"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    error={formik.errors.category}
                  >
                    {categoryList.map((c) => (
                      <option value={c.id} key={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="lg:col-span-2">
                  <Input
                    label="Price *"
                    name="price"
                    placeholder="Enter price amount"
                    value={formik.values.price}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        formik.handleChange(e);
                      }
                    }}
                    error={formik.errors.price}
                  />
                </div>
                <div className="lg:col-span-2">
                  <div>
                    <label className="form-control-label">Discount</label>
                    <div className="flex items-center">
                      <input
                        className={
                          "form-control-group-end " +
                          (formik.errors.discount ? "invalid" : "")
                        }
                        id="discount.value"
                        name="discount.value"
                        type="text"
                        placeholder={`Enter discount ${
                          formik.values.discount.type === "percent"
                            ? "percentage"
                            : "value"
                        }`}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            formik.handleChange(e);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.discount.value}
                      />
                      <select
                        className="border-l-0 focus:border-gray-300 border-gray-300 focus:ring-0 bg-gray-100"
                        id="discount.type"
                        name="discount.type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.discount.type}
                      >
                        <option value="fixed">.00</option>
                        <option value="percent">%</option>
                      </select>
                      <div className="border border-l-0 bg-gray-100 border-gray-300 rounded-r h-[42px] px-4 flex items-center justify-center">
                        <input
                          name="isDiscount"
                          checked={formik.values.isDiscount}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type="checkbox"
                          className={
                            "focus:ring-0 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          }
                        />
                        <label className="ml-2 text-gray-700">Apply</label>
                      </div>
                    </div>
                    {formik.errors.discount && (
                      <div className="form-control-error">
                        {formik.errors.discount}
                      </div>
                    )}
                  </div>
                </div>

                <div className="lg:col-span-1">
                  <Select
                    label="Size *"
                    name="size"
                    value={formik.values.size}
                    onChange={formik.handleChange}
                    error={formik.errors.size}
                  >
                    {sizeList.map((p, i) => (
                      <option value={p} key={i}>
                        {p}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="lg:col-span-1">
                  <Select
                    label="Material *"
                    name="material"
                    value={formik.values.material}
                    onChange={formik.handleChange}
                    error={formik.errors.material}
                  >
                    {materialList.map((p, i) => (
                      <option value={p} key={i}>
                        {p}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="lg:col-span-2">
                  <label className="form-control-label mb-3">Colors *</label>
                  <ChipGroups
                    list={colorList}
                    checkedList={formik.values.colors}
                    onCheckedListChange={(list) =>
                      formik.setFieldValue("colors", list)
                    }
                  />
                  {formik.errors.colors && (
                    <div className="form-control-error">
                      {formik.errors.colors}
                    </div>
                  )}
                </div>

                <hr className="lg:col-span-2 my-2" />

                <div className="lg:col-span-1">
                  <CheckBox
                    label="Available"
                    name="available"
                    helperText={
                      formik.values.available ? "In Stock" : "Out Of Stock"
                    }
                    checked={formik.values.available}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="lg:col-span-1">
                  <CheckBox
                    label="New Arrival"
                    name="newArrival"
                    helperText="Mark as new arrival"
                    checked={formik.values.newArrival}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="lg:col-span-1">
                  <CheckBox
                    label="Popular"
                    name="popular"
                    helperText="Mark as popular"
                    checked={formik.values.popular}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="lg:col-span-1">
                  <CheckBox
                    label="Hidden"
                    name="hidden"
                    helperText="Do not show in listing"
                    checked={formik.values.hidden}
                    onChange={formik.handleChange}
                  />
                </div>

                <hr className="lg:col-span-2 my-2" />

                <div className="lg:col-span-2">
                  <label className="form-control-label mb-2">
                    Product Images *
                  </label>
                  <ProductImages
                    images={formik.values.images}
                    onImagesChange={(blobs, urls) => {
                      formik.setFieldValue("files", blobs);
                      formik.setFieldValue("urls", urls);
                    }}
                  />
                </div>
                <div className="lg:col-span-2">
                  <TextArea
                    label="Description"
                    name="description"
                    placeholder="Enter description"
                    rows={6}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </form>
      </div>
      <div></div>
    </div>
  );
}

export default ProductEdit;
