import { useFormik } from "formik";
import { DefaultButton, PrimaryButton } from "../common/Buttons";
import { Input } from "../common/FormControls";
import { parseNumber } from "../common/utils";

function DeliveryFeesDialogContent({
  index,
  deliveryFees = { township: "", fee: "" },
  handleClose,
  list = [],
}) {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...deliveryFees },
    validate: (values) => {
      let errors = {};
      if (
        (!values.township || values.township.trim().length === 0) &&
        (!values.fee || values.fee.length === 0)
      ) {
        errors.township = "Please enter township.";
        errors.fee = "Please enter delivery fee.";
      }
      if (!values.fee || values.fee.length === 0) {
        errors.fee = "Please enter delivery fee.";
      }
      if (!values.township || values.township.trim().length === 0) {
        errors.township = "Please enter township.";
      }
      if (values.township) {
        let i = list.findIndex(
          (t) =>
            t.township.toLowerCase().trim().replace(/\s+/g, "") ===
            values.township.toLowerCase().trim().replace(/\s+/g, "")
        );
        if (i !== index) {
          let duplicate = list.find(
            (t) =>
              t.township.toLowerCase().trim().replace(/\s+/g, "") ===
              values.township.toLowerCase().trim().replace(/\s+/g, "")
          );
          if (duplicate) {
            errors.township = "Township name already exits.";
          }
        }
      }

      return errors;
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      let d = { ...values };
      d["fee"] = parseNumber(values.fee);
      handleClose(d);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col mt-4">
      <div className="mb-6">
        <Input
          label="Township *"
          name="township"
          placeholder="Enter township name"
          value={formik.values.township}
          error={formik.errors.township}
          onChange={formik.handleChange}
        />
      </div>
      <div className="mb-6">
        <Input
          label="Fee *"
          name="fee"
          placeholder="Enter delivery fee"
          value={formik.values.fee}
          error={formik.errors.fee}
          onChange={(e) => {
            if (!isNaN(e.target.value)) {
              formik.handleChange(e);
            }
          }}
        />
      </div>
      <div className="flex flex-row-reverse space-x-reverse space-x-2">
        <PrimaryButton type="submit">
          {index === -1 ? "Add" : "Change"}
        </PrimaryButton>
        <DefaultButton
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </DefaultButton>
      </div>
    </form>
  );
}

export default DeliveryFeesDialogContent;
